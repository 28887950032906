import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'app/_services/user.service';
import { GeneralService } from 'app/_services/general.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  formModel = {
    UserName: '',
    Password: ''
  };
  constructor(private userServ: UserService,
    private toastr: ToastrService,
    private genFun: GeneralService,
    private router: Router
    ) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    console.log(form.value);
    this.userServ.login(form.value).then(
      (res: any)=>{
        

        localStorage.setItem('user_info', JSON.stringify(res));
        
        this.router.navigate(['admin/dashboard']);
        console.log('res' , res);
      },
      (err)=>{
        console.log('err' , err);

        // console.log('err.status' , err.status);

        // if (err.status == 400)
        this.toastr.error('Incorrect username or password.', 'Authentication failed.',{timeOut: 4000});
//          else
        // console.log(err);
      }
    );
  }

}

import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PagesLayoutComponent } from './layouts/pages-layout/pages-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { AuthGuard } from './auth/auth.guard';
import { PrintLayoutComponent } from './layouts/print-layout/print-layout.component';
import { InvoiceComponent } from './pages/admin/Reports/invoice/invoice.component';
const adminModule = () => import('./pages/admin/admin.module').then((x) => x.AdminModule);


export const AppRoutes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full',
  // },
  {path: '', component: PagesLayoutComponent,
  children: [{ path: '', component: HomeComponent }]},
  {path: 'login', pathMatch: 'full', component: LoginComponent },
  {path: 'admin', loadChildren: adminModule , canActivate:[AuthGuard],data :{permittedRoles:['Admin']}},
  {path: 'forbidden',component:ForbiddenComponent},




  // {
  //   path: '',
  //   component: AdminLayoutComponent,
  //   children: [
  //       {
  //     path: '',
  //     loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  // }]},
  // {
  //   path: '**',
  //   redirectTo: 'dashboard'
  // }
]

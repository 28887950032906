import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class GeneralService {

  isLoading: boolean;


  constructor(public http: HttpClient,
    private location: Location,
    private nzMessageService: NzMessageService,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService) {
    this.isLoading = false;
    console.log('Hello GeneralProvider Provider');
  }

  public ValidateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public goBack() {
    this.location.back();
  }

  public showLoading() {
    if (this.isLoading === false) {
      this.isLoading = true;
      this.SpinnerService.show();
    }
  }

  public showSweetMsg(title, message, type: toastrType) {
    let sweetMsgType: any;
    if (type == toastrType.success)
      sweetMsgType = 'success';
    else if (type == toastrType.Error)
      sweetMsgType = 'error';
    Swal.fire(
      title,
      message, sweetMsgType
    );
  }

  public confirmSweetMsg(title, message, btnText) {
    return new Promise((resolve, reject) => {

      Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: btnText
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(true);
        }
        else
          resolve(false);
      })
    });
  }

  public getJsonDateToForm(json) {
    var d = new Date(json);
    //var datestring =("0"+(d.getMonth()+1)).slice(-2) +"/" +  ("0" + d.getDate()).slice(-2) + "/" +   + d.getFullYear();
    var datestring = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);

    return datestring;

  }

  public formatDate(d) {
    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [month, day, year].join('/');
}

  public showToastrMsg(title, message, type: toastrType) {
    if (type == toastrType.Error)
      this.nzMessageService.error(message);
    else if (type == toastrType.success)
      this.nzMessageService.success(message);
    else if (type == toastrType.info)
      this.nzMessageService.info(message);

    // if (type == toastrType.Error)
    //   this.toastr.error(message, title, { timeOut: 4000 });
    // else if (type == toastrType.success)
    //   this.toastr.show(message, title, { timeOut: 4000 }, type.toString());

  }

  public hideLoading() {
    if (this.isLoading) {
      this.isLoading = false;
      this.SpinnerService.hide();
    }
  }

  public getDomain(DMN_ID) {
    return this.httpGetData(environment.apiUrl + 'Lookups/getDomainData?DMN_Id=' + DMN_ID);
  }

  public localHttpGet(url) {
      return this.http.get(url,{ responseType: 'text' });
  }

  public httpGet1(url) {
    let authUser: any;
      authUser = environment.authUserData;
      const httpOpt = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (authUser && authUser.token ? authUser.token : '')
        }),
        withCredentials: true
      };

      return this.http.get(environment.apiUrl + url, httpOpt);
  }

  public httpGet2(url) {
    return new Observable((observer) => {

    let authUser: any;
      authUser = environment.authUserData;
      const httpOpt = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (authUser && authUser.token ? authUser.token : '')
        }),
        withCredentials: true
      };

      this.http.get(environment.apiUrl + url, httpOpt).subscribe(
        (res: any) => {
          if (!environment.production) { console.log('general provider response', res); }
          this.hideLoading();
          // tslint:disable-next-line: curly
          observer.next(res);
        },
        err => {
          this.hideLoading();
          if (!environment.production) { console.log('general provider error', err); }
          console.log('test error ', err.ExceptionMessage);
          // Error
          if (!this.isNullOrEmpty(err.error) && !this.isNullOrEmpty(err.error.message)) {
            observer.error(err.error.message);
          }
          else if (!this.isNullOrEmpty(err.error) && !this.isNullOrEmpty(err.error.response) && err.error.response.length > 0) {
            observer.error(err.error.response[0]);
          }
          else {
            observer.error(err);
          }
        }
      );

    });
  }

  public httpGetData(url: string,withLoading = true) {
    if(withLoading)
      this.showLoading();

    return new Promise((resolve, reject) => {
      let authUser: any;
      authUser = environment.authUserData;
      const httpOpt = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (authUser && authUser.token ? authUser.token : '')
        }),
        withCredentials: true
      };

      this.http.get(environment.apiUrl + url, httpOpt).subscribe(
        (res: any) => {
          if (!environment.production) { console.log('general provider response', res); }
          this.hideLoading();
          // tslint:disable-next-line: curly
          resolve(res);
        },
        err => {
          this.hideLoading();
          if (!environment.production) { console.log('general provider error', err); }
          console.log('test error ', err.ExceptionMessage);
          // Error
          if (!this.isNullOrEmpty(err.error) && !this.isNullOrEmpty(err.error.message)) {
            reject(err.error.message);
          }
          else if (!this.isNullOrEmpty(err.error) && !this.isNullOrEmpty(err.error.response) && err.error.response.length > 0) {
            reject(err.error.response[0]);
          }
          else {
            reject(err);
          }
        }
      );
    });
  }

  public httpDeleteData(url: string) {
    this.showLoading();
    return new Promise((resolve, reject) => {

      let authUser: any;
      authUser = environment.authUserData;
      const httpOpt = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (authUser && authUser.token ? authUser.token : '')
        }),
        withCredentials: true
      };

      this.http.delete(environment.apiUrl + url, httpOpt).subscribe(
        (res: any) => {
          if (!environment.production) { console.log('general provider response', res); }
          this.hideLoading();
          // tslint:disable-next-line: curly
          resolve(res);
        },
        err => {
          this.hideLoading();
          if (!environment.production) { console.log('general provider error', err); }
          // Error
          if (!this.isNullOrEmpty(err.error) && !this.isNullOrEmpty(err.error.message)) {
            reject(err.error.message);
          }
          else {
            reject(err);
          }
        }
      );
    });
  }

  public isNullOrEmpty(value: any) {
    return !value;
  }

  public httpPostData(url: string, data: any, withCredentials1: boolean) {
    this.showLoading();
    let authUser: any;
    authUser = environment.authUserData;
    const httpOpt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (authUser && authUser.token ? authUser.token : '')
      }),
      withCredentials: true
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(environment.apiUrl + url, data, httpOpt)
        .toPromise()
        .then(
          (res: any) => {
            this.hideLoading();
            if (!environment.production) { console.log('httpPostData response', res); }
            resolve(res);
          },
          err => {
            this.hideLoading();
            if (!environment.production) { console.log('general provider error', err); }
            console.log('test error ', err.ExceptionMessage);
            // Error
            if (!this.isNullOrEmpty(err.error) && !this.isNullOrEmpty(err.error.message)) {
              reject(err.error.message);
            }
            else if (!this.isNullOrEmpty(err.message) && !this.isNullOrEmpty(err.message)) {
              reject(err.message);
            }
            else if (!this.isNullOrEmpty(err.error) && !this.isNullOrEmpty(err.error.response) && err.error.response.length > 0) {
              reject(err.error.response[0]);
            }
            else {
              reject(err);
            }
          }
        );
    });

    return promise;
  }


  public httpPutData(url: string, data: any, withCredentials1: boolean) {
    this.showLoading();
    let authUser: any;
    authUser = environment.authUserData;
    const httpOpt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (authUser && authUser.token ? authUser.token : '')
      }),
      withCredentials: true
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .put(environment.apiUrl + url, data, httpOpt)
        .toPromise()
        .then(
          (res: any) => {
            this.hideLoading();
            if (!environment.production) { console.log('httpPostData response', res); }
            resolve(res);
          },
          err => {
            this.hideLoading();
            if (!environment.production) { console.log('general provider error', err); }
            console.log('test error ', err.ExceptionMessage);
            // Error
            if (!this.isNullOrEmpty(err.error) && !this.isNullOrEmpty(err.error.message)) {
              reject(err.error.message);
            }
            else if (!this.isNullOrEmpty(err.error) && !this.isNullOrEmpty(err.error.response) && err.error.response.length > 0) {
              reject(err.error.response[0]);
            }
            else {
              reject(err);
            }
          }
        );
    });

    return promise;
  }

}


export enum toastrType {
  Error,
  success,
  info,
  warning

}

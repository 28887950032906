import { Component, OnInit } from '@angular/core';
import { PrintService } from 'app/_services/Application/print.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: [ './admin-layout.component.css']  
})
export class AdminLayoutComponent implements OnInit {

  isPrinting = false;
  envv :any;

  constructor(){
    this.envv = environment;
    this.isPrinting = environment.isPrinting;
  }

  ngOnInit() { }
}

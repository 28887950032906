import { Injectable } from '@angular/core';
import { GeneralService } from './general.service';
import { FormBuilder, Validators, FormGroup, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private genServ: GeneralService) { }

  login(formData) {
    return this.genServ.httpPostData('/ApplicationUser/AdminLogin', formData,false);
  }

  getUserProfile() {
    return this.genServ.httpGetData('/UserProfile');
  }


  roleMatch(allowedRoles): boolean {
    let isMatch = false;
    let user_info = JSON.parse(localStorage.getItem('user_info')).loginResponse;
   environment.authUserData =   user_info ;
    console.log('user_info' , user_info);
    console.log('tokenExpireOn' , user_info.tokenExpireOn);
    console.log('tokenExpireOn' , new Date(user_info.tokenExpireOn));
    if(new Date(user_info.tokenExpireOn) < new Date())
      return false;

    console.log('user_info[0] ' ,user_info.token.split('.')[1]);
    console.log('payLoad ' ,window.atob(user_info.token.split('.')[1]));
    let payLoad = JSON.parse(window.atob(user_info.token.split('.')[1]));
    let userRole = payLoad.role;

    console.log(allowedRoles ,userRole);

    allowedRoles.forEach((element) => {
      if (userRole == element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

}

import { Component, OnInit } from '@angular/core';

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  class: string;
  collapse?: string;
  icontype: string;
  // icon: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}


export const ROUTES: RouteInfo[] = [
  { path: 'dashboard', title: 'Dashboard', icontype: 'nc-icon nc-bank', class: '', type: 'link', },
  { path: 'Contacts', title: 'Contacts', icontype: 'nc-icon nc-bank', class: '', type: 'link', },

  { path: 'Property', title: 'Property Inventory', icontype: 'nc-icon nc-bank', class: '', type: 'link', },

  { path: 'PropertySearch', title: 'Property Search', icontype: 'nc-icon nc-bank', class: '', type: 'link', },

  { path: 'Contract', title: 'Contract Search', icontype: 'nc-icon nc-bank', class: '', type: 'link', },

  { path: 'Cheque', title: 'Cheque Managment', icontype: 'nc-icon nc-bank', class: '', type: 'link', },



  {
    path: 'Accountant/', title: 'Accountant', collapse: 'Journal', icontype: 'nc-icon nc-layout-11', class: '', type: 'sub', children:
      [
        { path: 'ChartOfAccounts', title: 'Chart Of Accounts', ab: '' },
        { path: 'Journal', title: 'Journals', ab: '' },
        { path: 'PostVouchers', title: 'Posting', ab: '' },
        { path: 'ReverseVouchers', title: 'Reverse Journal', ab: '' },

        { path: 'Receipts', title: 'Receipts', ab: '' },

      ]
  },
  {
    path: 'Reports/Accountant/', title: 'Reports', collapse: 'reports', icontype: 'nc-icon nc-layout-11', class: '', type: 'sub', children:
      [
        { path: 'GeneralJournals', title: 'General Journals', ab: '' },
        { path: 'GeneralLedgerBalances', title: 'General Ledger Balances', ab: '' },
        { path: 'StatmentOfAccount', title: 'Statment Of Account', ab: '' },
        { path: 'TrailBalance', title: 'Trail Balance', ab: '' },
        { path: 'BalanceSheet', title: 'Balance Sheet', ab: '' },
      ]
  },
  {
    path: 'Administration', title: 'Administration', collapse: 'administration', icontype: 'nc-icon nc-layout-11', class: '', type: 'sub', children:
      [
        { path: 'Banks', title: 'Bank Accounts', ab: '' },
        { path: 'ContractAccountSetup', title: 'Contract Accounts Setup', ab: '' },
        { path: 'Countries', title: 'Countries', ab: '' },
        { path: 'Templates', title: 'Template', ab: '' },
        { path: 'ContactTypes', title: 'Contact Types', ab: '' },
        { path: 'SystemSettings', title: 'System Settings', ab: '' },
        { path: 'Workflow', title: 'Workflow', ab: '' },

      ]

  },

  // { path: 'users', title: 'Users-', icontype: 'nc-icon nc-ruler-pencil', class: '', type: 'link', },

  // { path: 'loyaltyProgram',         title: 'Loyalty Programs',             icon:'nc-diamond',    class: '' },
  // { path: 'clinic',          title: 'Clinic',              icon:'nc-pin-3',      class: '' },
  // { path: 'doctor',         title: 'Doctors',           icon:'nc-bell-55',    class: '' },
  // { path: 'patient',         title: 'Patients',           icon:'nc-bell-55',    class: '' },

  // { path: 'offer',          title: 'Offers',      icon:'nc-single-02',  class: '' },
  // { path: '/table',         title: 'Table List',        icon:'nc-tile-56',    class: '' },
  // { path: '/typography',    title: 'Typography',        icon:'nc-caps-small', class: '' },
  // { path: '/upgrade',       title: 'Upgrade to PRO',    icon:'nc-spaceship',  class: 'active-pro' },
];

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    console.log('MENU', this.menuItems);
  }
}
